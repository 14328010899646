import {
  Box,
  Snackbar,
  SnackbarProps,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import CustomTypography from "./CustomTypography.web";

import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import {
  CheckCircle,
  Error,
  SvgIconComponent,
  Warning,
} from "@material-ui/icons";
import clsx from "clsx";

export enum CustomSnackbarType {
  Success = "Success",
  Warning = "Warning",
  Error = "Error",
}

export interface Props extends SnackbarProps, WithStyles<any> {
  classes: ClassNameMap<any>;
  type?: CustomSnackbarType;
}

const styles = createStyles({
  container: {
    display: "flex",
    alignItems: "center",
    padding: 8,
    borderRadius: 8,

    "&.success": {
      backgroundColor: "#34D399",
    },

    "&.warning": {
      backgroundColor: "#F59E0B",
    },

    "&.error": {
      backgroundColor: "#DC2626",
    },
  },

  icon: {
    color: "#FFFFFF",
    width: 20,
    height: "auto",
  },

  message: {
    marginLeft: 8,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "24px",
    color: "#FFFFFF",
  },
});

interface SnackbarData {
  [className: string]: {
    className: any;
    icon: SvgIconComponent;
  };
}

const CustomSnackbarData: SnackbarData = {
  [CustomSnackbarType.Success]: {
    className: "success",
    icon: CheckCircle,
  },

  [CustomSnackbarType.Warning]: {
    className: "warning",
    icon: Warning,
  },

  [CustomSnackbarType.Error]: {
    className: "error",
    icon: Error,
  },
};

export class CustomSnackbar extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { type, message, classes, ...rest } = this.props;

    const data = !type ? null : (CustomSnackbarData[type] as any);

    return data ? (
      <Snackbar {...rest}>
        <Box className={clsx(classes.container, data.className)}>
          <data.icon className={classes.icon} />
          <CustomTypography className={classes.message}>
            {message}
          </CustomTypography>
        </Box>
      </Snackbar>
    ) : (
      <Snackbar {...rest} />
    );

    // switch (type) {
    //   case CustomSnackbarType.Success:
    //     return (
    //       <Snackbar {...rest}>
    //         <Box className={clsx(classes.container, classes.containerSuccess)}>
    //           <CheckCircle className={classes.icon} />
    //           <CustomTypography className={classes.message}>
    //             {message}
    //           </CustomTypography>
    //         </Box>
    //       </Snackbar>
    //     );

    //   case CustomSnackbarType.Warning:
    //     return <Snackbar></Snackbar>;

    //   case CustomSnackbarType.Error:
    //     return <Snackbar></Snackbar>;

    //   default:

    // }
  }
}

export default withStyles(styles)(CustomSnackbar);
