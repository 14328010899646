import React from "react";
// Customizable Area Start
import { Box, createStyles, withStyles } from "@material-ui/core";
import ForgotPasswordKitchenController, {
  Props,
} from "./ForgotPasswordKitchenController.web";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import AuthKitchenLayout from "../../../components/src/AuthKitchenLayout.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomFormInputField from "../../../components/src/CustomFormInputField.web";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import { kitchenLogo } from "./assets";

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },

  logo: {
    width: 113,
    height: "auto",
  },

  heading: {
    marginTop: 32,
  },

  form: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    maxWidth: 362,
    position: "relative",
  },

  formInner: {
    marginTop: 16,
  },

  goBackLogin: {
    marginTop: 24,
  },

  goBackLoginText: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "22px",
  },

  submitBtn: {
    marginTop: 24,
  },

  forgotPasswordSnackbar: {
    position: "absolute",
    left: 0,
    bottom: 0,
  },
});

const validationSchema = Yup.object({
  email: Yup.string()
    .nullable()
    .email("Invalid email address")
    .required("Please enter your email"),
});
// Customizable Area End

export class ForgotPasswordKitchen extends ForgotPasswordKitchenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { forgotPasswordSnackbar, forgotPasswordResponse, loading } =
      this.state;

    return (
      <AuthKitchenLayout>
        <Box className={classes.container}>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={this.forgotPassword}
            data-test-id="forgot-password-formik"
          >
            {(props) => {
              this.updateForgotPasswordFormProps(props);

              return (
                <Form
                  data-test-id="forgot-password-form"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                >
                  <img className={classes.logo} src={kitchenLogo} alt="logo" />

                  <CustomTypography
                    className={classes.heading}
                    color="primary"
                    variant="h2"
                  >
                    Forgot Password?
                  </CustomTypography>

                  <Box className={classes.formInner}>
                    <CustomFormInputField
                      fullWidth
                      name="email"
                      label="Email"
                      placeholder="Enter email address"
                      variant="outlined"
                      error={props.touched.email && !!props.errors.email}
                      data-test-id="email-input"
                      disabled={loading}
                    />
                  </Box>

                  <CustomButton
                    className={classes.submitBtn}
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-test-id="submit-btn"
                    fullWidth
                    disabled={loading}
                  >
                    Submit
                  </CustomButton>

                  <Box className={classes.goBackLogin}>
                    <CustomTypography className={classes.goBackLoginText}>
                      Go back{" "}
                      <CustomTypography
                        variant="button"
                        data-test-id="go-back-login-btn"
                        onClick={this.goBackToLogin}
                      >
                        Login
                      </CustomTypography>
                    </CustomTypography>
                  </Box>

                  <CustomSnackbar
                    className={classes.forgotPasswordSnackbar}
                    autoHideDuration={3000}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    open={forgotPasswordSnackbar}
                    type={forgotPasswordResponse.type}
                    message={forgotPasswordResponse.message}
                    onClose={this.closeForgotPasswordSnackbar}
                  />
                </Form>
              );
            }}
          </Formik>
        </Box>
      </AuthKitchenLayout>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(ForgotPasswordKitchen);
