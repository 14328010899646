import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  users: any[];
  search: string;
  filter: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class KitchenAccountManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  filterMenuList = [
    "All",
    "Admin",
    "Manager",
    "Reporting",
    "Operations",
    "Support",
    "Finance",
    "Account Manager",
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      users: [],
      filter: "All",
      search: "",
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const users = [
      "John Doe",
      "Jane Smith",
      "David Brown",
      "Emily White",
      "Alex Green",
      "John Doe",
      "Jane Smith",
      "David Brown",
      "John Doe",
      "Jane Smith",
      "David Brown",
      "Emily White",
    ];

    this.setState({
      users: users.map((username, index) => ({
        id: `Z${501 + index}`,
        time: new Date(),
        username,
        email: `${username.replace(/\s/g, "").toLowerCase()}@domain.com`,
        role: "Admin",
      })),
    });
    // Customizable Area End
  }

  // Customizable Area Start
  changeFilter = (filter: string) => {
    this.setState({
      filter,
    });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
}
