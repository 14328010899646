import {
  Box,
  TypographyVariant,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import CustomTypography from "./CustomTypography.web";

const styles = createStyles({
  container: {
    display: "flex",
    alignItems: "center",

    "&.is-good": {
      "& *": {
        color: "#059669",
      },
    },

    "&.is-bad": {
      "& *": {
        color: "#DC2626",
      },
    },
  },

  icon: {
    fontSize: 18,
    marginLeft: 5,
  },
});

export interface Props extends WithStyles<any> {
  className?: string;
  children: React.ReactNode;
  isGood?: boolean;
  isIncrease?: boolean;
  variant?: TypographyVariant;
  hideIcon?: boolean;
}

export class CustomTypographyIndex extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const {
      classes,
      children,
      isGood,
      isIncrease,
      hideIcon,
      variant,
      className,
    } = this.props;

    return (
      <Box
        className={clsx(className, classes.container, {
          "is-good": isGood,
          "is-bad": !isGood,
        })}
      >
        <CustomTypography variant={variant}>{children}</CustomTypography>
        {!hideIcon && (
          <>
            {isIncrease ? (
              <ArrowUpward className={classes.icon} />
            ) : (
              <ArrowDownward className={classes.icon} />
            )}
          </>
        )}
      </Box>
    );
  }
}

export default withStyles(styles)(CustomTypographyIndex);
