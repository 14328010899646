import { WithStyles } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { FormikProps } from "formik";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  loginSnackbar: boolean;
  loginSnackbarResponse: {
    type?: CustomSnackbarType;
    message: string;
  };

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountLoginKitchenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginApiId: string = "";

  loginFormProps?: FormikProps<{
    email: string;
    password: string;
  }> = undefined;

  token: string = "";
  accountType: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationEmailLogInMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,

      loginSnackbar: false,
      loginSnackbarResponse: {
        type: undefined,
        message: "",
      },
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.token = await StorageProvider.get("token");
    this.accountType = await StorageProvider.get("account_type");

    if (this.token && this.accountType === "kitchen_account") {
      this.props.navigation.navigate("DashboardKitchen");
    }
    // Customizable Area End
  }

  // Customizable Area Start
  login = (values: { email: string; password: string }) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const data = {
      type: "kitchen_account",
      attributes: values,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginKitchenEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleLogin = async (responseJson: any, formErrorHandler?: void) => {
    this.setState({
      loading: false,
    });

    if (
      responseJson.errors &&
      responseJson.errors[0]?.failed_login &&
      this.loginFormProps
    ) {
      this.loginFormProps.setErrors({
        email: "Email is address is not exist with us!",
      });

      return;
    }

    if (responseJson.error || responseJson.errors || !responseJson.meta) {
      this.setState({
        loginSnackbarResponse: {
          type: CustomSnackbarType.Error,
          message: "Error! Please try again",
        },
      });

      this.openLoginSnackbar();
      return;
    }

    await StorageProvider.set("token", responseJson.meta.token);
    await StorageProvider.set("refresh_token", responseJson.meta.refresh_token);
    await StorageProvider.set("account_id", responseJson.meta.id);
    await StorageProvider.set("account_type", "kitchen_account");

    this.props.navigation.navigate("DashboardKitchen");
  };

  forgotPassword = () => {
    this.props.navigation.navigate("ForgotPasswordKitchen");
  };

  updateLoginFormProps = (
    props: FormikProps<{
      email: string;
      password: string;
    }>
  ) => {
    this.loginFormProps = props;
  };

  openLoginSnackbar = () => {
    this.setState({
      loginSnackbar: true,
    });
  };

  closeLoginSnackbar = () => {
    this.setState({
      loginSnackbar: false,
    });
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.loginApiId) {
      this.handleLogin(responseJson);
    }
    // Customizable Area End
  }
}
