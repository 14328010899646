import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import {
  deFlag,
  ukFlag,
  dashboard,
  report,
  orders,
  rating,
  adv,
  promo,
  compensations,
  users,
  menu,
  time,
  finance,
  branch,
} from "./assets";
import DashboardKitchen from "../../../blocks/dashboard/src/DashboardKitchen.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import KitchenAccountManagement from "../../../blocks/social-media-account/src/KitchenAccountManagement.web";
import KitchenMenuManagement from "../../../blocks/catalogue/src/KitchenMenuManagement.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pageName: string;
  mobileOpen: boolean;
  isClosing: boolean;
  selectedLanguage: string;
  languages: {
    name: string;
    value: string;
    flag: string;
  }[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuKitchenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  performanceMonitoringRoutes = [
    {
      to: "/kitchen/owner/dashboard",
      name: "Dashboard",
      component: DashboardKitchen,
      icon: dashboard,
    },

    {
      to: "/kitchen/owner/reports",
      name: "Reports",
      icon: report,
    },

    {
      to: "/kitchen/owner/orders",
      name: "Orders",
      icon: orders,
    },

    {
      to: "/kitchen/owner/ratings-and-reviews",
      name: "Ratings and Reviews",
      icon: rating,
    },
  ];

  growYourBusinessRoutes = [
    {
      to: "/kitchen/owner/advertising",
      name: "Advertising",
      icon: adv,
    },

    {
      to: "/kitchen/owner/promotions",
      name: "Promotions",
      icon: promo,
    },
  ];

  manageYourBusinessRoutes = [
    {
      to: "/kitchen/owner/compensations",
      name: "Compensations",
      icon: compensations,
    },

    {
      to: "/kitchen/owner/user-management",
      name: "User Management",
      icon: users,
      component: KitchenAccountManagement,
    },

    {
      to: "/kitchen/owner/menu-management",
      name: "Menu Management",
      icon: menu,
      component: KitchenMenuManagement,
    },

    {
      to: "/kitchen/owner/opening-times",
      name: "Opening Times",
      icon: time,
    },

    {
      to: "/kitchen/owner/finance",
      name: "Finance",
      icon: finance,
    },

    {
      to: "/kitchen/owner/branch-status",
      name: "Branch Status",
      icon: branch,
    },
  ];

  token: string = "";
  accountType: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      pageName: "",
      mobileOpen: false,
      isClosing: false,
      selectedLanguage: "DE",
      languages: [
        { name: "UK", value: "UK", flag: ukFlag },
        { name: "DE", value: "DE", flag: deFlag },
      ],
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.token = await StorageProvider.get("token");
    this.accountType = await StorageProvider.get("account_type");

    if (!this.token || this.accountType !== "kitchen_account") {
      this.handleLogout();

      return;
    }

    const pathname = window.location.pathname;

    const currentPage = this.allRoutes.find((route) => route.to === pathname);

    this.setPageName(currentPage?.name || "");
    // Customizable Area End
  }

  // Customizable Area Start
  allRoutes = [
    ...this.performanceMonitoringRoutes,
    ...this.growYourBusinessRoutes,
    ...this.manageYourBusinessRoutes,
  ];

  changeLanguage = (e: any) => {
    this.setState({
      selectedLanguage: e.target.value,
    });
  };

  setMobileOpen = (mobileOpen: boolean) => {
    this.setState({
      mobileOpen,
    });
  };

  setIsClosing = (isClosing: boolean) => {
    this.setState({
      isClosing,
    });
  };

  handleDrawerClose = () => {
    this.setIsClosing(true);
    this.setMobileOpen(false);
  };

  handleDrawerTransitionEnd = () => {
    this.setIsClosing(false);
  };

  handleDrawerToggle = () => {
    if (!this.state.isClosing) {
      this.setMobileOpen(!this.state.mobileOpen);
    }
  };

  setPageName = (pageName: string) => {
    this.setState({ pageName });
  };

  changePage = (pageName: string) => {
    this.setPageName(pageName);

    if (this.state.mobileOpen) {
      this.handleDrawerClose();
    }
  };

  handleLogout = async () => {
    await StorageProvider.remove("token");
    await StorageProvider.remove("refresh_token");
    await StorageProvider.remove("account_id");
    await StorageProvider.remove("account_type");

    this.props.navigation.navigate("EmailAccountLoginKitchen");
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
}
