import React from "react";
// Customizable Area Start
import { Box, withStyles, createStyles, Tab } from "@material-ui/core";
import KitchenMenuManagementController, {
  MenuTab,
  Props,
} from "./KitchenMenuManagementController.web";
import CustomTabs from "../../../components/src/CustomTabs";
import KitchenMenuManagementOverview from "./KitchenMenuManagementOverview.web";
import KitchenMenuManagementChoiceGroup from "./KitchenMenuManagementChoiceGroup.web";
import KitchenMenuManagementSeeChanges from "./KitchenMenuManagementSeeChanges.web";

const styles = createStyles({
  content: {
    padding: 12,
    borderRadius: 8,
    border: "1px solid #EDEDED",
    minHeight: 300,
    borderTopLeftRadius: 0,
  },
});
// Customizable Area End

export class KitchenMenuManagement extends KitchenMenuManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { menuTab, filter, menuCategories, categoryGroups } = this.state;

    return (
      <Box className={classes.container}>
        <Box className={classes.inner}>
          <CustomTabs variant="scrollable" scrollButtons="auto" value={menuTab}>
            <Tab
              data-test-id="overview-tab"
              label="Overview"
              value={MenuTab.Overview}
              onClick={() => this.changeMenuTab(MenuTab.Overview)}
            />
            <Tab
              data-test-id="choice-groups-tab"
              label="Choice Groups"
              value={MenuTab.ChoiceGroups}
              onClick={() => this.changeMenuTab(MenuTab.ChoiceGroups)}
            />
            <Tab
              data-test-id="see-changes-tab"
              label="See Changes (3)"
              value={MenuTab.SeeChanges}
              onClick={() => this.changeMenuTab(MenuTab.SeeChanges)}
            />
          </CustomTabs>

          <Box className={classes.content}>
            {(() => {
              switch (menuTab) {
                case MenuTab.Overview:
                  return (
                    <KitchenMenuManagementOverview
                      filter={filter}
                      menuCategories={menuCategories}
                      filterMenuList={this.filterMenuList}
                      changeFilter={this.changeFilter}
                    />
                  );

                case MenuTab.ChoiceGroups:
                  return (
                    <KitchenMenuManagementChoiceGroup
                      choiceGroups={categoryGroups}
                    />
                  );

                case MenuTab.SeeChanges:
                  return <KitchenMenuManagementSeeChanges />;

                default:
                  return <></>;
              }
            })()}
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(KitchenMenuManagement);
