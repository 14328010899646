import {
  Typography,
  TypographyProps,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

export interface Props extends WithStyles<any> {}

export type CustomTypographyProps = TypographyProps & Props;

const styles = createStyles({
  customTypography: {
    color: "#333333",

    "&.MuiTypography-colorPrimary": {
      color: "#FF0000",
    },

    "&.MuiTypography-button": {
      color: "#FF6666",
      fontWeight: 700,
      fontSize: 14,
      lineHeight: "22px",
      cursor: "pointer",
      letterSpacing: "initial",
      textTransform: "initial",
      fontFamily: "Inter",
    },

    "&.MuiTypography-h2": {
      fontWeight: 600,
      fontSize: 24,
      lineHeight: "28.18px",
      fontFamily: "Raleway",
    },

    "&.MuiTypography-h5": {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: "16.44px",
      fontFamily: "Raleway",
      letterSpacing: "0.04em",
    },

    "&.MuiTypography-h6": {
      fontFamily: "Raleway",
      fontSize: 14,
      fontWeight: 700,
      lineHeight: "16.44px",
      letterSpacing: " 0.04em",
      color: "#333333",
    },

    "&.MuiTypography-body1": {
      fontSize: 14,
      lineHeight: "16.44px",
      fontFamily: "Raleway",
      color: "#333333",
    },

    "&.MuiTypography-body2": {
      fontSize: 10,
      lineHeight: "11.74px",
      fontFamily: "Raleway",
      color: "#333333",
    },

    "&.MuiTypography-caption": {
      fontSize: 12,
      lineHeight: "14.52px",
      fontFamily: "Inter",
      color: "#B5B5B5",
      letterSpacing: " 0.04em",
      fontWeight: 600,
    },

    "&.MuiTypography-subtitle1": {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: "21.78px",
      fontFamily: "Inter",
      letterSpacing: " 0.04em",
    },

    "&.MuiTypography-subtitle2": {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: "18.78px",
      fontFamily: "Raleway",
      letterSpacing: " 0.04em",
    },
  },
});

export class CustomTypography extends React.Component<CustomTypographyProps> {
  constructor(props: CustomTypographyProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { children, classes, className, ...rest } = this.props;

    return (
      <Typography
        className={clsx(classes.customTypography, className)}
        {...rest}
      >
        {children}
      </Typography>
    );
  }
}

export default withStyles(styles)(CustomTypography);
