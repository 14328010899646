// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
// import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Share from "../../blocks/share/src/Share";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Sorting from "../../blocks/sorting/src/Sorting";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Compilanform from "../../blocks/compilanform/src/Compilanform";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Invoicereconciliationusingpromocodes from "../../blocks/invoicereconciliationusingpromocodes/src/Invoicereconciliationusingpromocodes";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Uniphonicsmsgatewayintegration from "../../blocks/uniphonicsmsgatewayintegration/src/Uniphonicsmsgatewayintegration";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Manualorderpreparationtimesetting from "../../blocks/manualorderpreparationtimesetting/src/Manualorderpreparationtimesetting";
import Notifications from "../../blocks/notifications/src/Notifications";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Paytapsintegration1 from "../../blocks/paytapsintegration1/src/Paytapsintegration1";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Cfmodifytheorderdetails from "../../blocks/cfmodifytheorderdetails/src/Cfmodifytheorderdetails";
import Location from "../../blocks/location/src/Location";
import Analytics from "../../blocks/analytics/src/Analytics";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import ApplePayIntegration from "../../blocks/applepayintegration/src/ApplePayIntegration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Automaticuserblocking1 from "../../blocks/automaticuserblocking1/src/Automaticuserblocking1";
import LoyaltySystem from "../../blocks/loyaltysystem/src/LoyaltySystem";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Geofence from "../../blocks/geofence/src/Geofence";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import EmailAccountLoginKitchen from "../../blocks/email-account-login/src/EmailAccountLoginKitchen.web"
import ForgotPasswordKitchen from '../../blocks/forgot-password/src/ForgotPasswordKitchen.web';
import ResetPasswordKitchen from '../../blocks/forgot-password/src/ResetPasswordKitchen.web';
import NavigationMenuKitchen from '../../blocks/navigationmenu/src/NavigationMenuKitchen.web';

const routeMap = {
  EmailAccountLoginKitchen: {
    component: EmailAccountLoginKitchen,
    path: "/kitchen/login"
  },
  ForgotPasswordKitchen: {
    component: ForgotPasswordKitchen,
    path: "/kitchen/forgot-password"
  },
  ResetPasswordKitchen: {
    component: ResetPasswordKitchen,
    path: "/kitchen/reset-password"
  },
  DashboardKitchen: {
    component: NavigationMenuKitchen,
    path: "/kitchen/owner"
  },
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Share:{
 component:Share,
path:"/Share"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Compilanform:{
 component:Compilanform,
path:"/Compilanform"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Invoicereconciliationusingpromocodes:{
 component:Invoicereconciliationusingpromocodes,
path:"/Invoicereconciliationusingpromocodes"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Uniphonicsmsgatewayintegration:{
 component:Uniphonicsmsgatewayintegration,
path:"/Uniphonicsmsgatewayintegration"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Manualorderpreparationtimesetting:{
 component:Manualorderpreparationtimesetting,
path:"/Manualorderpreparationtimesetting"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Paytapsintegration1:{
 component:Paytapsintegration1,
path:"/Paytapsintegration1"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Cfmodifytheorderdetails:{
 component:Cfmodifytheorderdetails,
path:"/Cfmodifytheorderdetails"},
Location:{
 component:Location,
path:"/Location"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
ApplePayIntegration:{
 component:ApplePayIntegration,
path:"/ApplePayIntegration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Automaticuserblocking1:{
 component:Automaticuserblocking1,
path:"/Automaticuserblocking1"},
LoyaltySystem:{
 component:LoyaltySystem,
path:"/LoyaltySystem"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Geofence:{
 component:Geofence,
path:"/Geofence"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100vw' }}>
          {/* <TopNav /> */}
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;
