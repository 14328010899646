import {
  WithStyles,
  createStyles,
  withStyles,
  Box,
  Theme,
} from "@material-ui/core";
import React from "react";

export interface Props extends WithStyles<any> {}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      minHeight: "100vh",

      "& *": {
        boxSizing: "border-box",
      },
    },

    containerLeft: {
      marginRight: "50%",
      height: "100%",
      padding: 20,

      [theme.breakpoints.down("sm")]: {
        marginRight: 0,
      },
    },

    containerRight: {
      width: "50%",
      height: "100%",
      position: "fixed",
      top: 0,
      right: 0,
      backgroundImage: `url(${require("./auth-kitchen-bg.png")})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "50%",

      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  });

export class AuthKitchenLayout extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, children } = this.props;

    return (
      <Box className={classes.container}>
        <Box className={classes.containerLeft}>{children}</Box>
        <Box className={classes.containerRight} />
      </Box>
    );
  }
}

export default withStyles(styles)(AuthKitchenLayout);
