import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { menuCategory } from "./assets";
import { CustomFilterMenu } from "../../../components/src/CustomFilter.web";

export enum MenuTab {
  Overview = "overview",
  ChoiceGroups = "ChoiceGroups",
  SeeChanges = "SeeChanges",
}

export interface Dish {
  id: string;
  name: string;
  available: boolean;
  image: string;
  price: number;
}

export interface MenuCategory {
  id: string;
  name: string;
  image: string;
  description: string;
  dishes: Dish[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  menuTab: MenuTab;
  filter: string;
  menuCategories: MenuCategory[];
  categoryGroups: MenuCategory[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class KitchenMenuManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  filterMenuList: CustomFilterMenu[] = [
    {
      id: uuidv4(),
      name: "All",
      value: "all",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      menuTab: MenuTab.Overview,
      filter: "All",
      menuCategories: [],
      categoryGroups: [],
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start

    const categories = [
      "Ramadan Items",
      "Broth",
      "Rice",
      "Beverages",
      "Chicken",
      "Meat",
      "Chicken Section",
      "Feast section",
    ];

    const dishes = ["Mahamoos", "Buryani", "Mandi", "White"];

    const data = categories.map((category) => ({
      id: uuidv4(),
      name: category,
      image: menuCategory,
      description: "Promotions",
      dishes: dishes.map((dish, index) => ({
        id: uuidv4(),
        name: dish,
        available: index % 2 === 0,
        image: menuCategory,
        price: 17.72,
      })),
    }));

    this.setState({
      menuCategories: data,
      categoryGroups: data,
    });
    // Customizable Area End
  }

  // Customizable Area Start
  changeMenuTab = (menuTab: MenuTab) => {
    this.setState({
      menuTab,
    });
  };

  changeFilter = (filter: string) => {
    this.setState({
      filter,
    });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
}
