import {
  Select,
  SelectProps,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import React from "react";

export interface Props extends SelectProps {}

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
        border: "1px solid #EDEDED",
      },

      notchedOutline: {
        border: "none",
      },
    },

    MuiSelect: {
      iconOutlined: {
        right: 7,
      },

      select: {
        padding: 12,
        display: "flex",
        alignItems: "center",
      },

      outlined: {
        borderRadius: 8,
      },
    },
  },
});

export class CustomSelect extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { children, ...rest } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Select {...rest}>{children}</Select>
      </ThemeProvider>
    );
  }
}

export default CustomSelect;
