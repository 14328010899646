import {
  Box,
  InputLabel,
  TextFieldProps,
  createStyles,
} from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ErrorMessage, Field } from "formik";
import React from "react";
import CustomInputField from "./CustomInputField.web";

export interface Props extends WithStyles<any> {}

export type CustomFormInputFieldProps = TextFieldProps & Props;

const styles = createStyles({
  container: {
    "& + &": {
      marginTop: 24,
    },
  },

  label: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "22px",
    color: "#333333",
    fontFamily: "Raleway",
  },

  input: {
    marginTop: 4,

    "& .MuiFormHelperText-root": {
      margin: 0,
      color: "#DC2626",
      fontWeight: 400,
      lineHeight: "18px",
      fontSize: 12,
      fontFamily: "Inter",
    },
  },
});

export class CustomFormInputField extends React.Component<CustomFormInputFieldProps> {
  constructor(props: CustomFormInputFieldProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { name, label, classes, error, ...rest } = this.props;

    return (
      <Box className={classes?.container}>
        <InputLabel className={classes.label} htmlFor={name}>
          {label}
        </InputLabel>

        <Field
          className={classes.input}
          as={CustomInputField}
          name={name}
          error={error}
          helperText={error && <ErrorMessage name={name} />}
          {...rest}
        />
      </Box>
    );
  }
}

export default withStyles(styles)(CustomFormInputField);
