import {
  withStyles,
  WithStyles,
  createStyles,
  Menu,
  Box,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import CustomButton from "./CustomButton.web";
import CustomTypography from "./CustomTypography.web";
import { Check, FilterList } from "@material-ui/icons";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";

export interface CustomFilterMenu {
  id: string;
  name: string;
  value: string;
}
export interface Props extends WithStyles<any> {
  value?: string;
  menu: CustomFilterMenu[];

  onChange(value: string): void;
}

const styles = createStyles({
  customFilterMenu: {
    minWidth: 192,
  },

  customFilterItem: {
    display: "flex",
    alignItems: "center",
    padding: "9px 18px",

    "&.is-selected": {
      backgroundColor: "#FC9797",
      borderLeft: 3,
      borderLeftColor: "#FF6666",
    },
  },

  customFilterName: {
    fontFamily: "Inter !important",
    fontSize: "14px !important",
    lineHeight: "22px !important",
    marginLeft: 8,
  },
});

export class CustomFilter extends React.Component<
  Props,
  {
    filterMenu: HTMLElement | null;
  }
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      filterMenu: null,
    };
  }

  openFilter = (event: any) => {
    this.setState({
      filterMenu: event.currentTarget,
    });
  };

  closeFilter = () => {
    this.setState({
      filterMenu: null,
    });
  };

  changeFilter = (filter: string) => {
    this.props.onChange(filter);

    this.closeFilter();
  };

  render(): React.ReactNode {
    const { menu, value, classes } = this.props;
    const { filterMenu } = this.state;
    const id = uuidv4();

    return (
      <Box className={classes.customFilter}>
        <CustomButton
          id={`filter-button-${id}`}
          startIcon={<FilterList />}
          aria-controls={filterMenu ? `filter-menu-${id}` : undefined}
          aria-haspopup="true"
          aria-expanded={filterMenu ? "true" : undefined}
          onClick={this.openFilter}
        >
          Filter
        </CustomButton>
        <Menu
          id={`filter-menu-${id}`}
          anchorEl={filterMenu}
          open={Boolean(filterMenu)}
          onClose={this.closeFilter}
          MenuListProps={{
            className: classes.customFilterMenu,
            "aria-labelledby": `filter-button-${id}`,
          }}
        >
          {menu.map((filter) => {
            const selected = value === filter.value;

            return (
              <MenuItem
                className={clsx(classes.customFilterItem, {
                  "is-selected": selected,
                })}
                key={filter.id}
                onClick={() => this.changeFilter(filter.value)}
              >
                {selected && <Check fontSize="small" />}
                <CustomTypography className={classes.customFilterName}>
                  {filter.name}
                </CustomTypography>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    );
  }
}

export default withStyles(styles)(CustomFilter);
