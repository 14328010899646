import React from "react";
// Customizable Area Start
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Toolbar,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core";
import { ExpandMore, Menu } from "@material-ui/icons";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomTypography from "../../../components/src/CustomTypography.web";
import NavigationMenuKitchenController, {
  Props,
} from "./NavigationMenuKitchenController.web";
import clsx from "clsx";
import { logout, bgKitchenNav, notification } from "./assets";

const styles = (theme: any) =>
  createStyles({
    container: {
      display: "flex",
    },

    appBar: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - 265px)`,
        marginLeft: 265,
      },

      "&.MuiAppBar-colorDefault": {
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
      },
    },

    appBarHead: {
      position: "relative",
      height: 9,

      "&::before": {
        position: "absolute",
        content: '""',
        top: 0,
        left: 0,
        width: "50%",
        height: 9,
        backgroundColor: "#FF6666",
      },

      "&::after": {
        position: "absolute",
        content: '""',
        top: 0,
        right: 0,
        width: "50%",
        height: 9,
        backgroundColor: "#FFCC00",
      },
    },

    appBarHeadToolbar: {
      flexWrap: "wrap",

      [theme.breakpoints.down("xs")]: {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },

    appBarTool: {
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
      gap: 15,

      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        order: 3,
        width: "100%",
        marginTop: 12,
      },
    },

    appBarInfo: {
      marginLeft: 15,
      display: "flex",
      alignItems: "center",
      gap: 15,

      [theme.breakpoints.down("xs")]: {
        marginLeft: "auto",
        order: 2,
      },
    },

    appBarNotification: {
      backgroundColor: "#F8F8F8",
      border: "1px solid #EDEDED",
      width: 44,
      height: 44,
    },

    appBarNotificationBadge: {
      "& .MuiBadge-dot": {
        minWidth: 5,
        height: 5,
      },

      "& .MuiBadge-colorPrimary": {
        backgroundColor: "#2EBD6B",
      },
    },

    appBarAvatar: {
      backgroundColor: "#FF6666",
      width: 44,
      height: 44,
    },

    main: {
      flexGrow: 1,
      padding: theme.spacing(3),

      [theme.breakpoints.up("sm")]: {
        width: "calc(100% - 265px)",
      },
    },

    mainToolbar: {
      [theme.breakpoints.down("xs")]: {
        height: 125,
      },
    },

    outletsButton: {
      minWidth: 178,
      minHeight: 44,
    },

    languageSelect: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },

    outletsButtonContent: {
      display: "flex",
      flexDirection: "column",
      textAlign: "left",
      width: "100%",
    },

    languageMenuItem: {
      display: "flex",
      alignItems: "center",
    },

    languageMenuItemFlag: {
      width: 24,
      height: 18,
      objectFit: "cover",
    },

    languageMenuItemName: {
      marginLeft: 8,
      fontSize: "12px !important",
      lineHeight: "18px !important",
    },

    drawerMobile: {
      [theme.breakpoints.up("sm")]: {
        width: 265,
        flexShrink: 0,
      },
    },

    drawerMobilePaper: {
      width: 265,
      backgroundColor: "#FFFBF4",
      minHeight: "100vh",

      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },

    drawerWeb: {
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },

    drawerWebPaper: {
      boxSizing: "border-box",
      width: 265,
      backgroundColor: "#FFFBF4",
    },

    iconButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },

    nav: {
      [theme.breakpoints.up("sm")]: {
        width: 265,
        flexShrink: 0,
      },
    },

    navInner: {
      paddingTop: 20,
    },

    restaurant: {
      display: "flex",
      alignItems: "center",
    },

    restaurantLogo: {
      backgroundColor: "#D9D9D9",
      width: 50,
      height: 50,
      borderRadius: 8,
      marginRight: 9,
    },

    navTitle: {
      padding: "0 15px",
    },

    navItem: {
      padding: "8px 15px",
      display: "flex",
      alignItems: "center",

      "&.active": {
        color: "#FFFFFF",
        backgroundColor: "#FF6666",

        "& img": {
          filter: "grayscale(100%) brightness(500%)",
        },
      },
    },

    navItemIcon: {
      width: 24,
      height: 24,
    },

    navItemName: {
      marginLeft: 8,

      "& .MuiTypography-root": {
        fontFamily: "Raleway",
        fontSize: 14,
        lineHeight: "24px",
      },
    },

    navDivider: {
      height: 6,
      borderRadius: "initial",
    },

    navMenuBottom: {
      padding: 20,
      marginTop: 100,
    },

    logoutBtn: {
      fontWeight: 600,
      fontFamily: "Raleway",
      fontSize: 14,
      lineHeight: "24px",
    },

    navMenu: {
      background: `url(${bgKitchenNav})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "118%",
      backgroundSize: "contain",
    },

    navMenuToolbar: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  });

const theme = createTheme({});

const ComingSoon = () => (
  <Box>
    <CustomTypography variant="h2">Coming soon !</CustomTypography>
  </Box>
);

const NavMenuInner = (props: any) => (
  <Box className={props.classes.navInner}>
    <CustomTypography className={props.classes.navTitle} variant="h5">
      {props.title}
    </CustomTypography>

    <List>
      {props.routes.map((route: any) => (
        <ListItem
          className={clsx(props.classes.navItem, {
            active: route.name === props.currentPage,
          })}
          disableGutters
          button
          component={Link}
          to={route.to}
          key={route.to}
          onClick={() => props.changePage(route.name)}
          data-test-id={route.name}
        >
          <img
            src={route.icon}
            alt="image/svg+xml"
            className={props.classes.navItemIcon}
          />

          <ListItemText
            primary={route.name}
            className={props.classes.navItemName}
          />
        </ListItem>
      ))}
    </List>
  </Box>
);

const NavMenu = (props: any) => (
  <Box className={props.classes.navMenu}>
    <Toolbar className={props.classes.navMenuToolbar}>
      <Box className={props.classes.restaurant}>
        <Box className={props.classes.restaurantLogo} />
        <CustomTypography variant="subtitle2">Restaurant Logo</CustomTypography>
      </Box>
    </Toolbar>

    <NavMenuInner
      data-test-id="performance-monitoring"
      classes={props.classes}
      title="Performance Monitoring"
      routes={props.performanceMonitoringRoutes}
      changePage={props.changePage}
      currentPage={props.currentPage}
    />

    <Divider color="#EDEDED" className={props.classes.navDivider} />

    <NavMenuInner
      data-test-id="grow-your-business"
      classes={props.classes}
      title="Grow your business"
      routes={props.growYourBusinessRoutes}
      changePage={props.changePage}
      currentPage={props.currentPage}
    />

    <Divider color="#EDEDED" className={props.classes.navDivider} />

    <NavMenuInner
      data-test-id="manage-your-business"
      classes={props.classes}
      title="Manage your business"
      routes={props.manageYourBusinessRoutes}
      changePage={props.changePage}
      currentPage={props.currentPage}
    />

    <Box className={props.classes.navMenuBottom}>
      <CustomButton
        className={props.classes.logoutBtn}
        color="secondary"
        variant="contained"
        startIcon={<img src={logout} alt="logout" />}
        onClick={props.handleLogout}
      >
        Logout
      </CustomButton>
    </Box>
  </Box>
);
// Customizable Area End

export class NavigationMenuKitchen extends NavigationMenuKitchenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;

    const { languages, selectedLanguage, mobileOpen, pageName } = this.state;

    return (
      <Router>
        <Box className={classes.container}>
          <AppBar position="fixed" className={classes.appBar} color="default">
            <Box className={classes.appBarHead} />
            <Toolbar className={classes.appBarHeadToolbar}>
              <IconButton
                className={classes.iconButton}
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
              >
                <Menu />
              </IconButton>

              <CustomTypography variant="h2">{pageName}</CustomTypography>

              <Box className={classes.appBarTool}>
                <CustomButton
                  variant="outlined"
                  className={classes.outletsButton}
                >
                  <Box className={classes.outletsButtonContent}>
                    <CustomTypography variant="body2">Outlets</CustomTypography>
                    <CustomTypography variant="h5">All (7/7)</CustomTypography>
                  </Box>

                  <ExpandMore />
                </CustomButton>

                <CustomSelect
                  data-test-id="language-select"
                  className={classes.languageSelect}
                  variant="outlined"
                  value={selectedLanguage}
                  onChange={this.changeLanguage}
                >
                  {languages.map((language) => (
                    <MenuItem
                      className={classes.languageMenuItem}
                      value={language.value}
                      key={language.value}
                    >
                      <img
                        className={classes.languageMenuItemFlag}
                        src={language.flag}
                        alt={language.name}
                      />
                      <CustomTypography
                        variant="subtitle1"
                        className={classes.languageMenuItemName}
                      >
                        {language.name}
                      </CustomTypography>
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Box>

              <Box className={classes.appBarInfo}>
                <IconButton className={classes.appBarNotification}>
                  <Badge
                    className={classes.appBarNotificationBadge}
                    color="primary"
                    variant="dot"
                    overlap="circular"
                  >
                    <img src={notification} alt="notification" />
                  </Badge>
                </IconButton>

                <Avatar className={classes.appBarAvatar}>CS</Avatar>
              </Box>
            </Toolbar>
          </AppBar>

          <Box component="nav" className={classes.nav}>
            <Drawer
              className={classes.drawerMobile}
              variant="temporary"
              open={mobileOpen}
              onTransitionEnd={this.handleDrawerTransitionEnd}
              ModalProps={{
                keepMounted: true,
              }}
              classes={{
                paper: classes.drawerMobilePaper,
              }}
              onClose={this.handleDrawerClose}
            >
              <NavMenu
                data-test-id="drawer-mobile"
                classes={classes}
                performanceMonitoringRoutes={this.performanceMonitoringRoutes}
                growYourBusinessRoutes={this.growYourBusinessRoutes}
                manageYourBusinessRoutes={this.manageYourBusinessRoutes}
                currentPage={pageName}
                changePage={this.changePage}
                handleLogout={this.handleLogout}
              />
            </Drawer>

            <Drawer
              variant="permanent"
              className={classes.drawerWeb}
              classes={{
                paper: classes.drawerWebPaper,
              }}
              open
            >
              <NavMenu
                data-test-id="drawer-web"
                classes={classes}
                performanceMonitoringRoutes={this.performanceMonitoringRoutes}
                growYourBusinessRoutes={this.growYourBusinessRoutes}
                manageYourBusinessRoutes={this.manageYourBusinessRoutes}
                currentPage={pageName}
                changePage={this.changePage}
                handleLogout={this.handleLogout}
              />
            </Drawer>
          </Box>

          <Box className={classes.main} component="main">
            <Toolbar className={classes.mainToolbar} />
            <Switch>
              {this.allRoutes.map((route: any) => (
                <Route path={route.to} key={route.to}>
                  {route.component ? (
                    <route.component navigation={navigation} id={route.name} />
                  ) : (
                    <ComingSoon />
                  )}
                </Route>
              ))}

              <Redirect from="/kitchen/owner*" to="/kitchen/owner/dashboard" />
            </Switch>
          </Box>
        </Box>
      </Router>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(NavigationMenuKitchen);
