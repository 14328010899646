import React from "react";
// Customizable Area Start
import { Box, WithStyles, createStyles, withStyles } from "@material-ui/core";

const styles = createStyles({
  container: {},
});

export interface Props extends WithStyles<any> {}
// Customizable Area End

export class KitchenMenuManagementSeeChanges extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;

    return <Box className={classes.container} />;
    // Customizable Area End
  }
}

export default withStyles(styles)(KitchenMenuManagementSeeChanges);
