import {
  Button,
  ButtonProps,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import React from "react";

export interface Props extends ButtonProps {}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF6666",
    },

    secondary: {
      main: "#FFCC00",
    },
  },

  typography: {
    button: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "initial",
      fontFamily: "Inter",
    },
  },

  overrides: {
    MuiButton: {
      containedPrimary: {
        padding: "10px 16px",
        color: "#FFFFFF",
        borderRadius: 8,
      },

      outlined: {
        border: "1px solid #EDEDED",
        borderRadius: 8,
      },
    },
  },
});

export class CustomButton extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { children, ...rest } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Button {...rest}>{children}</Button>
      </ThemeProvider>
    );
  }
}

export default CustomButton;
