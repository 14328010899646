import React from "react";
// Customizable Area Start
import {
  Box,
  IconButton,
  InputAdornment,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createStyles,
  withStyles,
} from "@material-ui/core";
import CustomInputField from "../../../components/src/CustomInputField.web";
import CustomTable from "../../../components/src/CustomTable";
import KitchenAccountManagementController, {
  Props,
} from "./KitchenAccountManagementController.web";
import moment from "moment";
import { AddCircleOutline, MoreVert, Search } from "@material-ui/icons";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomPagination from "../../../components/src/CustomPagination";
import CustomFilter from "../../../components/src/CustomFilter.web";

const styles = createStyles({
  container: {},

  actions: {
    display: "flex",
    gap: 10,
    justifyContent: "end",
    alignItems: "center",
  },

  userData: {
    marginTop: 24,
  },

  userTable: {},

  userTableId: {
    fontFamily: "Inter !important",
  },

  pagination: {
    display: "flex",
    justifyContent: "end",
    marginTop: 24,
  },
});
// Customizable Area End

export class KitchenAccountManagement extends KitchenAccountManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { users, filter } = this.state;

    return (
      <Box className={classes.container}>
        <Box className={classes.actions}>
          <CustomInputField
            variant="outlined"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />

          <CustomFilter
            value={filter}
            onChange={this.changeFilter}
            menu={this.filterMenuList.map((filter) => ({
              id: filter.toLowerCase(),
              name: filter,
              value: filter,
            }))}
          />

          <CustomButton
            startIcon={<AddCircleOutline />}
            variant="contained"
            color="primary"
          >
            Add User
          </CustomButton>
        </Box>

        <Box className={classes.userData}>
          <TableContainer>
            <CustomTable className={classes.userTable}>
              <TableHead>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell>Date & Time</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Email Address</TableCell>
                  <TableCell>User Role</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell className={classes.userTableId}>
                      {user.id}
                    </TableCell>
                    <TableCell>
                      {moment(user.time).format("DD MMM YYYY hh:mma")}
                    </TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>
                      <IconButton size="small">
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <Box className={classes.pagination}>
            <CustomPagination
              count={8}
              page={1}
              variant="text"
              siblingCount={-1}
              total={48}
              limit={12}
              showOffset
            />
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(KitchenAccountManagement);
