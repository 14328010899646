import {
  WithStyles,
  createStyles,
  withStyles,
  Box,
  Theme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from "@material-ui/core";
import React from "react";
import CustomTypography from "./CustomTypography.web";
import { Edit, ExpandMore, Height, MoreVert } from "@material-ui/icons";

export interface Props extends WithStyles<any> {
  name: string;
  image: string;
  description?: string;
  dishes: any[];
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      boxShadow: "initial",
      borderRadius: "0 !important",

      "& .MuiAccordionSummary-content": {
        margin: 0,
      },

      "& .MuiAccordionSummary-expandIcon": {
        position: "absolute",
        right: 14,
        top: 14,
        color: "#334333",
      },
    },

    summary: {
      display: "flex",
      alignItems: "initial",
      padding: 0,
      position: "relative",
    },

    inner: {
      display: "flex",
      alignItems: "center",
      padding: 12,
      border: "1px solid #EDEDED",
      borderRadius: 8,
      width: "100%",
    },

    image: {
      width: 50,
      height: 50,
      border: "1px solid #EDEDED",
      borderRadius: 8,
    },

    content: {
      marginLeft: 10,
    },

    name: {
      lineHeight: "24px",
      fontWeight: "600 !impotant" as any,
    },

    description: {
      color: "#FF6666 !important",
    },

    editButton: {
      marginLeft: 10,
      color: "#FF6666",
    },

    detailList: {
      backgroundColor: "#FFFBF4",
      maxHeight: 222,
      overflow: "auto",
      borderRadius: 8,
      padding: "10px !important",
      marginTop: 15,
      flexDirection: "column",
    },

    dish: {
      display: "flex",
      alignItems: "center",

      "& + & ": {
        marginTop: 15,
      },
    },

    dishHeading: {
      display: "flex",
      alignItems: "center",
    },

    dishStatus: {
      marginLeft: 5,
    },

    dishImage: {
      width: 50,
      height: 50,
      border: "1px solid #EDEDED",
      borderRadius: 8,
    },

    dishAvailable: {
      fontSize: "12px !important",
      lineHeight: "24px !important",
      color: "#059669",
    },

    dishUnavailable: {
      fontSize: "12px !important",
      lineHeight: "24px !important",
      color: "#DC2626",
    },

    dishContent: {
      marginLeft: 10,
      width: "100%",
    },

    dishPrice: {
      lineHeight: "24px !important",
      color: "#333333 !important",
      fontWeight: "400 !important" as any,
    },

    dishHeadButton: {
      color: "#333333",
    },

    dishMenuButton: {
      color: "#333333",
    },
  });

export class KitchenMenuCategoryAccordion extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, name, image, description, dishes } = this.props;

    return (
      <Accordion className={classes.container}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMore />}
        >
          <Box className={classes.inner}>
            <img className={classes.image} src={image} alt={name} />

            <Box className={classes.content}>
              <CustomTypography variant="subtitle2" className={classes.name}>
                {name}
              </CustomTypography>

              <CustomTypography
                variant="caption"
                className={classes.description}
              >
                {description}
              </CustomTypography>
            </Box>

            <IconButton className={classes.editButton}>
              <Edit />
            </IconButton>
          </Box>
        </AccordionSummary>

        <AccordionDetails className={classes.detailList}>
          {dishes.map((dish) => (
            <Box className={classes.dish} key={dish.id}>
              <IconButton className={classes.dishHeadButton}>
                <Height />
              </IconButton>

              <img
                src={dish.image}
                alt={dish.name}
                className={classes.dishImage}
              />

              <Box className={classes.dishContent}>
                <Box className={classes.dishHeading}>
                  <CustomTypography
                    variant="subtitle2"
                    className={classes.dishName}
                  >
                    {dish.name}
                  </CustomTypography>

                  <Box className={classes.dishStatus}>
                    {dish.available ? (
                      <CustomTypography
                        variant="subtitle2"
                        className={classes.dishAvailable}
                      >
                        Available
                      </CustomTypography>
                    ) : (
                      <CustomTypography
                        variant="subtitle2"
                        className={classes.dishUnavailable}
                      >
                        Unavailable
                      </CustomTypography>
                    )}
                  </Box>
                </Box>

                <CustomTypography
                  className={classes.dishPrice}
                  variant="caption"
                >
                  {`${dish.price} SAR`}
                </CustomTypography>
              </Box>

              <IconButton className={classes.dishMenuButton}>
                <MoreVert />
              </IconButton>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withStyles(styles)(KitchenMenuCategoryAccordion);
