import {
  Table,
  TableProps,
  ThemeProvider,
  WithStyles,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core";
import React from "react";

const theme = createTheme({
  overrides: {
    MuiTableHead: {
      root: {
        "& th": {
          fontFamily: "Raleway",
          fontWeight: 700,
          fontSize: 14,
          lineHeight: "22px",
          color: "#333333",
          padding: 8,
        },

        "& .MuiTableSortLabel-icon": {
          color: "#333333 !important",
          marginRight: 4,
        },
      },
    },

    MuiTableBody: {
      root: {
        "& td": {
          fontFamily: "Raleway",
          fontSize: 14,
          lineHeight: "16.44px",
          color: "#333333",
          padding: 8,
        },
      },
    },
  },
});

const styles = createStyles({
  container: {},
});

export interface Props extends TableProps {
  classes: any;
}

export class CustomTable extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, children, ...rest } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Table {...rest} className={classes.container}>
          {children}
        </Table>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(CustomTable);
