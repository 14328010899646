import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  WithStyles,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CustomButton from "../../../components/src/CustomButton.web";
import KitchenMenuCategoryAccordion from "../../../components/src/KitchenMenuCategoryAccordion.web";
import { MenuCategory } from "./KitchenMenuManagementController.web";

const styles = (theme: Theme) =>
  createStyles({
    container: {},

    top: {
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        alignItems: "center",
      },
    },

    title: {
      fontSize: "22px !important",
      lineHeight: "25.83px !important",
    },

    description: {
      marginTop: 8,
    },

    createButton: {
      [theme.breakpoints.down("xs")]: {
        marginTop: 15,
      },

      [theme.breakpoints.up("sm")]: {
        marginLeft: "auto",
      },
    },

    choiceGroup: {
      marginTop: 37,
    },

    categoryDataItem: {
      "& + &": {
        marginTop: 15,
      },
    },
  });

export interface Props extends WithStyles<any> {
  choiceGroups: MenuCategory[];
}
// Customizable Area End

export class KitchenMenuManagementChoiceGroup extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, choiceGroups } = this.props;

    return (
      <Box className={classes.container}>
        <Box className={classes.top}>
          <Box className={classes.heading}>
            <CustomTypography className={classes.title} variant="h2">
              Choice Group
            </CustomTypography>

            <CustomTypography className={classes.description} variant="body1">
              This is where you'll find the groups of choices your customers can
              add to their items
            </CustomTypography>
          </Box>

          <CustomButton
            className={classes.createButton}
            color="primary"
            variant="contained"
          >
            Create Choice Group
          </CustomButton>
        </Box>

        <Box className={classes.choiceGroup}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {choiceGroups.map(
                (choiceGroup, index) =>
                  (index + 1) % 2 !== 0 && (
                    <Box
                      className={classes.categoryDataItem}
                      key={choiceGroup.id}
                    >
                      <KitchenMenuCategoryAccordion
                        name={choiceGroup.name}
                        image={choiceGroup.image}
                        description={choiceGroup.description}
                        dishes={choiceGroup.dishes}
                      />
                    </Box>
                  )
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {choiceGroups.map(
                (choiceGroup, index) =>
                  (index + 1) % 2 === 0 && (
                    <Box
                      className={classes.categoryDataItem}
                      key={choiceGroup.id}
                    >
                      <KitchenMenuCategoryAccordion
                        name={choiceGroup.name}
                        image={choiceGroup.image}
                        description={choiceGroup.description}
                        dishes={choiceGroup.dishes}
                      />
                    </Box>
                  )
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(KitchenMenuManagementChoiceGroup);
