export const userProfile = require("../assets/user-profile.png");
export const deFlag = require("../assets/DE.png");
export const ukFlag = require("../assets/UK.png");
export const bgKitchenNav = require("../assets/bg_kitchen_nav.png");

export const adv = require("../assets/ic_adv.svg");
export const branch = require("../assets/ic_branch.svg");
export const compensations = require("../assets/ic_compensations.svg");
export const dashboard = require("../assets/ic_dashboard.svg");
export const finance = require("../assets/ic_finance.svg");
export const menu = require("../assets/ic_menu.svg");
export const promo = require("../assets/ic_promo.svg");
export const rating = require("../assets/ic_rating.svg");
export const report = require("../assets/ic_report.svg");
export const time = require("../assets/ic_time.svg");
export const users = require("../assets/ic_user.svg");
export const orders = require("../assets/ic_order.svg");
export const logout = require("../assets/ic_logout.svg");
export const notification = require("../assets/ic_notification.svg");
