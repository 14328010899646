import {
  IconButton,
  InputAdornment,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React from "react";
import CustomFormInputField, {
  CustomFormInputFieldProps,
} from "./CustomFormInputField.web";

interface S {
  show: boolean;
}

type Props = Omit<CustomFormInputFieldProps, "type">;

const styles = createStyles({});

export class CustomFormPasswordField extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  togglePassword = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  render(): React.ReactNode {
    const { show } = this.state;

    return (
      <CustomFormInputField
        {...(this.props as any)}
        type={show ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={this.togglePassword}
                edge="end"
              >
                {show ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

export default withStyles(styles)(CustomFormPasswordField);
