import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { FormikProps } from "formik";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  forgotPasswordSnackbar: boolean;
  forgotPasswordResponse: {
    type?: CustomSnackbarType;
    message: string;
  };
  loading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotPasswordKitchenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgotPasswordApiId: string = "";
  forgotPasswordFormProps?: FormikProps<{
    email: string;
  }>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      forgotPasswordSnackbar: false,
      forgotPasswordResponse: {
        type: undefined,
        message: "",
      },
      loading: false,
    };

    this.subScribedMessages = [
      getName(MessageEnum.NavigationEmailLogInMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  openForgotPasswordSnackbar = () => {
    this.setState({
      forgotPasswordSnackbar: true,
    });
  };

  closeForgotPasswordSnackbar = () => {
    this.setState({
      forgotPasswordSnackbar: false,
    });
  };

  handleForgotPassword = async (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (
      responseJson.errors &&
      responseJson.errors[0]?.account &&
      this.forgotPasswordFormProps
    ) {
      this.forgotPasswordFormProps.setErrors({
        email: responseJson.errors[0].account,
      });

      return;
    }

    if (responseJson.error || responseJson.errors) {
      this.setState({
        forgotPasswordResponse: {
          type: CustomSnackbarType.Error,
          message: "Error! Please try again",
        },
      });

      this.openForgotPasswordSnackbar();
      return;
    }

    this.setState({
      forgotPasswordResponse: {
        type: CustomSnackbarType.Success,
        message: "Success! Email Sent",
      },
    });

    this.openForgotPasswordSnackbar();
  };

  forgotPassword = (values: { email: string }) => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const data = {
      email: values.email,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.forgotPasswordApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordKitchenApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  goBackToLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginKitchen");
  };

  updateForgotPasswordFormProps = (
    props: FormikProps<{
      email: string;
    }>
  ) => {
    this.forgotPasswordFormProps = props;
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.forgotPasswordApiId) {
      this.handleForgotPassword(responseJson);
    }
    // Customizable Area End
  }
}
